
	import { Component, Mixins } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import UpgradeSubscriptionMixin from '@pixcap/ui-core/components/payment/UpgradeSubscriptionMixin';
	import { FREE_TRIAL_PLAN_INFO } from '@pixcap/ui-core/constants/subscription.constants';
	import { actionsWrapper as UserActions, mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import {
		Getters as UserGetters,
		GetterTypes as UserGetterTypes,
		GetterTypes as UserGettersTypes,
		NAMESPACE as USER_NAMESPACE,
		PAYMENT_STATUS,
		SUBSCRIPTION,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import UpgradeButton from '@pixcap/ui-core/components/payment/UpgradeButton.vue';

	import Button from '@pixcap/ui-library/components/Button.vue';
	import Button2 from '@pixcap/ui-library/components/Button/Button2.vue';
	/// icons list
	import IconCrown from '@pixcap/ui-library/components/Icons/IconCrown.vue';
	import { formatTime } from '@pixcap/ui-core/utils/DatetimeUtils';
	import { ABTestingCases } from '@pixcap/ui-core/models/store/app.interface';
	import ABTestingMixin from '@pixcap/ui-core/mixins/ABTestingMixin';
	import ABTesting from '@pixcap/ui-core/components/common/ABTesting.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import IconStarCircle from '@pixcap/ui-library/components/Icons/IconStarCircle.vue';

	@Component({
		name: 'Subscription',
		components: {
			ABTesting,
			IconCrown,
			BodyText,
			HeadingText,
			CaptionText,
			IconStarCircle,
			Button,
			Button2,
			UpgradeButton,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				user: (state: UserState) => state.personalData?.user,
			}),
			...mapGetters(USER_NAMESPACE, {
				isUnsubscribedUser: UserGettersTypes.IS_UNSUBSCRIBED_USER,
				isCanceledSubscription: UserGettersTypes.IS_CANCELED_SUBSCRIPTION,
				isFreeTrialActive: UserGettersTypes.IS_FREE_TRIAL_ACTIVE,
				getPricingForUser: UserGettersTypes.GET_PRICING_FOR_USER,
			}),
		},
	})
	export default class Subscription extends Mixins(UpgradeSubscriptionMixin, ABTestingMixin) {
		user: UserState['personalData']['user'];
		isCanceledSubscription: ReturnType<UserGetters[UserGettersTypes.IS_CANCELED_SUBSCRIPTION]>;
		isFreeTrialActive: ReturnType<UserGetters[UserGettersTypes.IS_FREE_TRIAL_ACTIVE]>;

		isLoadingInvoices = false;

		toggleCancelPlanModal() {
			UserMutations.toggleCancelPlanModal(this.$store);
		}

		get previousSubscriptionPrice() {
			return this.subscriptionInfo.price || null;
		}

		get monthlyCreditsBalance() {
			const monthlyRemaining = (this.user?.limits?.credits?.monthlyLimit ?? 0) - (this.user?.limits?.credits?.monthlyUsed ?? 0);
			return `${monthlyRemaining}/${this.user?.limits?.credits?.monthlyLimit ?? 0}`;
		}

		get toppedUpCredits() {
			return this.user?.limits?.credits?.topped ?? 0;
		}

		get openInBlankTab() {
			return ['CustomizerEditor', 'Editor'].includes(this.$route.name);
		}

		get subscription() {
			return this.subscriptionInfo.subscription;
		}

		get expireDate() {
			return formatTime(this.subscriptionInfo.currentPeriodEnd);
		}

		get upgradePlanTitle() {
			return this.abTestingVariant?.includes(ABTestingCases.UPGRADE_CTA_TEST)
				? // @ts-ignore
				  this.$t
					? // @ts-ignore
					  this.$t('page.user_settings.subscription.unlock_all')
					: 'Unlock All Features'
				: // @ts-ignore
				this.$t
				? // @ts-ignore
				  this.$t('page.user_settings.subscription.upgrade_plan')
				: 'Upgrade Plan';
		}

		// regarding subscription
		get isFreePlan() {
			return this.subscription === SUBSCRIPTION.BASIC;
		}

		get plan() {
			if (this.paymentStatus === PAYMENT_STATUS.FREE_TRIAL) {
				return FREE_TRIAL_PLAN_INFO;
			}
			const PRICING = this.getPricingForUser;
			if (this.subscription === SUBSCRIPTION.PRO_QUARTERLY) {
				return {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.pro_quarterly_plan') : 'Pro Quarterly Plan',
					price: this.previousSubscriptionPrice,
					period: 'For 3 months',
					showChangePlan: true,
					showIcon: false,
					message: 'Save more with Annual plan',
				};
			}
			if (this.subscription === SUBSCRIPTION.ELITE_QUARTERLY) {
				return {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.elite_quarterly_plan') : 'Elite Quarterly Plan',
					price: this.previousSubscriptionPrice,
					period: 'For 3 months',
					showChangePlan: true,
					showIcon: false,
					message: 'Save more with Annual plan',
				};
			}
			const PLAN_INFO = {
				[SUBSCRIPTION.BASIC]: {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.free_plan') : 'FREE Plan.',
					price: 'FREE',
					period: null,
					showChangePlan: true,
					showIcon: false,
					// @ts-ignore
					message: this.$t ? this.$t('page.user_settings.subscription.per_year') : 'Get more, create more!',
				},
				[SUBSCRIPTION.PRO_ANNUALLY]: {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.pro_annual_plan') : 'Pro Annual Plan',
					price: PRICING.CURRENCY_PREFIX + PRICING.PRO_ANNUAL.toLocaleString('en-US'),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.per_year') : 'Per year',
					showChangePlan: true,
					showIcon: false,
					// @ts-ignore
					message: this.$t
						? // @ts-ignore
						  this.$t(`page.user_settings.subscription.${this.isAnnualPlansOnly ? 'get_more_create_more' : 'save_more'}`)
						: this.isAnnualPlansOnly
						? 'Get more, create more!'
						: 'Saved more with Annual plan',
				},
				[SUBSCRIPTION.ELITE_ANNUALLY]: {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.elite_annual_plan') : 'Elite Annual Plan',
					price: PRICING.CURRENCY_PREFIX + PRICING.ELITE_ANNUAL.toLocaleString('en-US'),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.per_year') : 'Per year',
					showChangePlan: true,
					showIcon: false,
					// @ts-ignore
					message: this.$t
						? // @ts-ignore
						  this.$t(`page.user_settings.subscription.${this.isAnnualPlansOnly ? 'get_more_create_more' : 'save_more'}`)
						: this.isAnnualPlansOnly
						? 'Get more, create more!'
						: 'Saved more with Annual plan',
				},
			};
			if (PRICING.PRO_MONTH) {
				PLAN_INFO[SUBSCRIPTION.PRO_MONTHLY] = {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.pro_monthly_plan') : 'Pro Monthly Plan',
					price: PRICING.CURRENCY_PREFIX + PRICING.PRO_MONTH.toLocaleString('en-US'),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.per_month') : 'Per month',
					showChangePlan: true,
					showIcon: false,
					// @ts-ignore
					message: this.$t ? this.$t('page.user_settings.subscription.save_more') : 'Save more with Annual plan',
				};
			}
			if (PRICING.PRO_QUARTER) {
				PLAN_INFO[SUBSCRIPTION.PRO_QUARTERLY] = {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.pro_quarterly_plan') : 'Pro Quarterly Plan',
					price: PRICING.CURRENCY_PREFIX + PRICING.PRO_QUARTER.toLocaleString('en-US'),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.for_3_months') : 'For 3 months',
					showChangePlan: true,
					showIcon: false,
					// @ts-ignore
					message: this.$t ? this.$t('page.user_settings.subscription.save_more') : 'Save more with Annual plan',
				};
			}
			if (PRICING.ELITE_MONTH) {
				PLAN_INFO[SUBSCRIPTION.ELITE_MONTHLY] = {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.elite_monthly_plan') : 'Elite Monthly Plan',
					price: PRICING.CURRENCY_PREFIX + PRICING.ELITE_MONTH.toLocaleString('en-US'),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.per_month') : 'Per month',
					showChangePlan: true,
					showIcon: false,
					// @ts-ignore
					message: this.$t ? this.$t('page.user_settings.subscription.save_more') : 'Save more with Annual plan',
				};
			}
			if (PRICING.ELITE_QUARTER) {
				PLAN_INFO[SUBSCRIPTION.ELITE_QUARTERLY] = {
					// @ts-ignore
					name: this.$t ? this.$t('page.user_settings.subscription.plan_name.elite_quarterly_plan') : 'Elite Quarterly Plan',
					price: PRICING.CURRENCY_PREFIX + PRICING.ELITE_QUARTER.toLocaleString('en-US'),
					// @ts-ignore
					period: this.$t ? this.$t('page.user_settings.subscription.for_3_months') : 'For 3 months',
					showChangePlan: true,
					showIcon: false,
					// @ts-ignore
					message: this.$t ? this.$t('page.user_settings.subscription.save_more') : 'Save more with Annual plan',
				};
			}
			return PLAN_INFO[this.subscription || SUBSCRIPTION.BASIC];
		}

		handleUpgradePlan(href) {
			if (this.abTesting && this.abTestingVariant) {
				this.$pixcap.$mixpanel.people.set({ 'AB Testing segment id': this.abTesting.id });
				this.$pixcap.$mixpanel.track('AB Testing', {
					Cases: this.abTestingVariant,
					Value: this.upgradePlanTitle,
				});
			}
			if (this.openInBlankTab) window.open(href);
			else this.$router.push(href);
		}

		handlePendingOrFailedPayment() {
			if (this.subscriptionDueRenewal) {
				window.location.href = this.$router.resolve({ name: 'PricingPage' }).href;
			} else if (this.isSubscribedWithStripe) {
				this.openCustomerPortal();
			} else {
				if (
					this.subscription == SUBSCRIPTION.PRO_MONTHLY ||
					this.subscription == SUBSCRIPTION.PRO_QUARTERLY ||
					this.subscription == SUBSCRIPTION.PRO_ANNUALLY
				) {
					this.handleGetPro();
				} else if (
					this.subscription == SUBSCRIPTION.ELITE_MONTHLY ||
					this.subscription == SUBSCRIPTION.ELITE_QUARTERLY ||
					this.subscription == SUBSCRIPTION.ELITE_ANNUALLY
				) {
					this.handleGetElite();
				}
			}
		}

		clickCancel() {
			UserMutations.toggleCancelPlanModal(this.$store);
		}

		async clickRenew() {
			this.isLoading = true;
			await UserActions.renewSubscription(this.$store);
			this.isLoading = false;
		}

		async openInvoicesPortalPortal() {
			this.isLoadingInvoices = true;
			const url = await UserActions.fetchInvoicesPortal(this.$store);
			this.isLoadingInvoices = false;
			if (url) {
				const invoicesWindow = window.open(url);
				if (!invoicesWindow || invoicesWindow.closed) window.location.href = url;
			}
		}

		handleTopUpCredits() {
			UserMutations.setShowTopUpCreditsModal(this.$store, true);
		}
	}
