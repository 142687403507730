
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import { RESEND_EMAIL_TIMEOUT } from '@pixcap/ui-core/constants/app.constants';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	@Component({
		name: 'ResendVerificationButton',
		components: {
			BodyText,
			Button,
		},
	})
	export default class ResendVerificationButton extends Vue {
		@Prop() email: string;
		@Prop() className: string | undefined;
		@Prop() textFormat: boolean;
		isSendingVerification = false;
		verificationSent = false;
		intervalSeconds = RESEND_EMAIL_TIMEOUT;
		resendPasswordInterval;

		get disableResendVerification() {
			return this.verificationSent || !this.email;
		}

		get displayTimeout() {
			let seconds = this.intervalSeconds;
			const minutes = Math.floor(seconds / 60);
			seconds = seconds - minutes * 60;
			return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
		}

		get displayBtnText() {
			if (this.verificationSent) return `Please wait: ${this.displayTimeout}`;
			return 'Resend';
		}

		resendVerification() {
			if (this.disableResendVerification) return;
			this.isSendingVerification = true;
			AuthActions.resendEmailVerification(this.$store, this.email).finally(() => {
				this.isSendingVerification = false;
				this.verificationSent = true;
				this.initResendEmailTimeout();
			});
		}

		initResendEmailTimeout() {
			this.intervalSeconds = RESEND_EMAIL_TIMEOUT;

			this.resendPasswordInterval = setInterval(() => {
				if (this.intervalSeconds <= 0) {
					clearInterval(this.resendPasswordInterval);
					this.verificationSent = false;
				}
				this.intervalSeconds -= 1;
			}, 1000);
		}

		beforeDestroy() {
			clearInterval(this.resendPasswordInterval);
		}
	}
