
	import { Component, Vue } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { NAMESPACE as USER_NAMESPACE, UserState } from '@pixcap/ui-core/models/store/user.interface';
	import { mutationsWrapper as UserMutations, actionsWrapper as UserActions } from '@pixcap/ui-core/store/user';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
	import Cropper from '@pixcap/ui-core/components/common/Cropper.vue';
	import LoadingState from '@pixcap/ui-library/components/LoadingState.vue';
	import { $notify } from '@pixcap/ui-core/helpers/notification';
	import Modal from '@pixcap/ui-library/components/Modal.vue';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import { getCroppableImageState } from '@pixcap/ui-core/utils/images/getCroppableImageState';
	import { cropImageUsingCropBox } from '@pixcap/ui-core/utils/images/cropImageUsingCropBox';

	@Component({
		name: 'UserAvatarCropper',
		components: {
			Modal,
			Button,
			LoadingState,
			Cropper,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				imgSrc: (state: UserState) => state.CroppPictureModal,
			}),
		},
	})
	export default class UserAvatarCropper extends Vue {
		imgSrc: UserState['CroppPictureModal'];
		_cropInfo = null;
		imgFile = null;
		imageCropInfo = null;
		isLoading = true;
		avatarWidth = 235;
		avatarHeight = 235;
		onCropChange(cropInfo) {
			this._cropInfo = cropInfo;
		}

		toggleCroppAvatar() {
			UserMutations.toggleCroppAvatar(this.$store, null);
		}

		async handleSaveImage() {
			try {
				this.isLoading = true;
				const imageBlob = (await cropImageUsingCropBox(
					this.imgFile,
					{ width: this.avatarWidth, height: this.avatarHeight },
					this._cropInfo.cropBox || this.imageCropInfo.cropBox,
					true
				)) as Blob;
				await UserActions.changeAvatar(this.$store, imageBlob);
				this.toggleCroppAvatar();
			} catch (err) {
				$notify({
					type: NotificationType.ERROR,
					title: 'Error while updating avatar',
				});
			} finally {
				this.isLoading = false;
			}
		}
		async created() {
			const reader = new FileReader();
			reader.onload = async (event) => {
				this.imgFile = event.target.result;
				const imageState = await getCroppableImageState(this.imgFile as string, { width: this.avatarWidth, height: this.avatarHeight });
				const { w, h } = imageState.imageSize;
				const aspectRatio = w / h;
				const scale = aspectRatio >= 1 ? this.avatarWidth / w : this.avatarHeight / h;
				this.imageCropInfo = {
					imageBox: { width: imageState.imageSize.w, height: imageState.imageSize.h },
					renderBox: { width: w * scale, height: h * scale },
					cropBox: imageState.cropBox,
				};
				this.isLoading = false;
			};
			reader.readAsDataURL(this.imgSrc);
		}
	}
